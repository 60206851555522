<template>
  <header class="bg-black">
    <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
      <div class="w-full py-6 flex items-center justify-between">
        <div class="flex items-center">
          <a href="#">
            <img class="h-10 w-auto" :src="require('@/assets/img/iconweiss.svg')" alt="" />
          </a>
          <div class="ml-10 space-x-8">
            <a v-for="link in navigation" :key="link.name" :href="link.href" class="text-base font-medium text-white hover:text-indigo-50">
              {{ link.name }}
            </a>
          </div>
        </div>
        <div class="ml-10 space-x-4">
          <a href="#" class="inline-block py-2 px-4 border border-white rounded-md text-base font-medium text-white">Einloggen</a>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>

const navigation = [
  { name: 'Features', href: '#' },
  { name: 'Pakete', href: '#' },
  { name: 'FAQ', href: '#' },
  { name: '', href: '#' },
]

export default {
  name: "GlobalNavigation",
  data() {
    return {
      navigation
    }
  }
}
</script>

<style scoped>

</style>
