<template>
  <div class="py-16 bg-gradient-to-b from-black to-gray-800 overflow-hidden lg:py-24">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
<!--      <svg class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">-->
<!--        <defs>-->
<!--          <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">-->
<!--            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />-->
<!--          </pattern>-->
<!--        </defs>-->
<!--        <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />-->
<!--      </svg>-->

      <div class="relative">
        <h2 class="text-center text-3xl md:text-5xl leading-10 font-extrabold tracking-tight text-orange">Das beste FINDER Portal seit langem</h2>
        <p class="mt-4 max-w-4xl mx-auto text-center text-xl text-gray-100">Erleben Sie aktualisierte Karten, eine moderne Oberfläche und spannende neue Features</p>
      </div>

      <div v-for="(feature, index) in features" :key="feature.caption" class="features">
        <FeatureElementLeft v-if="index % 2 === 0" :feature="feature"></FeatureElementLeft>
        <FeatureElementRight v-else :feature="feature" />
      </div>

<!--      <svg class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784" aria-hidden="true">-->
<!--        <defs>-->
<!--          <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">-->
<!--            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />-->
<!--          </pattern>-->
<!--        </defs>-->
<!--        <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />-->
<!--      </svg>-->


    </div>
  </div>
</template>

<script>
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/vue/outline'
import FeatureElementLeft from "@/components/features/FeatureElementLeft";
import FeatureElementRight from "@/components/features/FeatureElementRight";
const transferFeatures = [
  {
    id: 1,
    name: 'Dabei sein ist alles',
    description:
        'Informieren Sie sich in Echtzeit über die erfassten Positionen und individualisieren Sie Ihre Konfiguration',
    icon: GlobeAltIcon,
  },
  {
    id: 2,
    name: 'No hidden fees',
    description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione',
    icon: ScaleIcon,
  },
  {
    id: 3,
    name: 'Transfers are instant',
    description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione',
    icon: LightningBoltIcon,
  },
]

// eslint-disable-next-line no-unused-vars
const features = [
  {
    caption: 'Dabei sein ist alles',
    description: 'Informieren Sie sich in Echtzeit über die erfassten Positionen und individualisieren Sie Ihre Konfiguration',
    image: require('@/assets/img/M1.png'),
    bullets: [
      {
        heading: 'Live-Verfolgung',
        description: 'Sie können jederzeit nachschauen, wo sich Ihr Tracker gerade befindet',
        icon: 'status-online',
        plus: false
      },
      {
        heading: 'Zurück in die Vergangenheit',
        description: 'Gefahrene Strecken, standardmäßig bis zu 100 Tage abrufbar',
        icon: 'arrow-sm-left',
        plus: false
      },
      {
        heading: 'Plug and Play',
        description: 'Kinderleichte Einrichtung von Alarmen und mehr',
        icon: 'light-bulb',
        plus: false
      }
    ]
  },
  {
    caption: 'Am Ball bleiben',
    description: 'Erhalten Sie Benachrichtigungen in Echtzeit auf all Ihren Geräten und verpassen Sie kein wichtiges Ereignis',
    image: require('@/assets/img/M2.png'),
    bullets: [
      {
        heading: 'Push-Benachrichtigungen',
        description: 'Auf die Zeit kommt es an, in Echtzeit übermitteln wir Alarme an Ihr Smartphone',
        icon: 'annotation',
        plus: false
      },
      {
        heading: 'E-Mail Nachricht',
        description: 'E-Mails mit detaillierten Informationen zu den ausgelösten Alarmen',
        icon: 'mail',
        plus: false
      },
      {
        heading: 'SMS-Benachrichtigung',
        description: 'Keine mobilen Daten? Kein Problem! Erhalten Sie Alarme per SMS Nachricht',
        icon: 'chat',
        plus: true
      }
    ]
  },
  {
    caption: 'Globale Konnektivität',
    description: 'Immer verbunden, fast überall - mit unserer globalen Sim-Karte',
    image: require('@/assets/img/M3_1.png'),
    bullets: [
      {
        heading: 'Weltweite Standortbestimmung',
        description: 'Sicher unterwegs in bis zu 100 Ländern.',
        icon: 'globe',
        plus: false
      },
      {
        heading: 'Keine Kostenfalle',
        description: 'Bei Nutzung im Ausland entstehen keine Zusatzkosten',
        icon: 'shield-check',
        plus: false
      },
      {
        heading: 'Redundante Abdeckung',
        description: 'Smarter Netzwechsel bei Störungen einzelner Anbieter',
        icon: 'chart-bar',
        plus: false
      }
    ]
  },

]

const communicationFeatures = [
  {
    id: 1,
    name: 'Mobile notifications',
    description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: AnnotationIcon,
  },
  {
    id: 2,
    name: 'Reminder emails',
    description:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: MailIcon,
  },
]
export default {
  name: "FeatureSection",
  components: {FeatureElementRight, FeatureElementLeft},
  data() {
    return {
      transferFeatures,
      communicationFeatures,
      features
    }
  }
}
</script>

<style scoped>

</style>
