<template>
  <div>
    <div class="relative">
      <div class="absolute inset-0">
        <video class="w-full h-full object-cover" autoplay loop muted>
          <source :src="require('@/assets/vid/PAJCompressed.mp4')" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div class="absolute inset-0 bg-orange/70 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">Alles startklar?</h1>
        <button type="button" class="inline-flex items-center mt-5 px-6 py-3 border border-white shadow-sm text-base font-medium rounded-md text-white">
          FINDER aktivieren
          <ChevronDoubleRightIcon class="ml-3 -mr-2.5 h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>

  </div>

</template>

<script>
import {ChevronDoubleRightIcon} from "@heroicons/vue/solid";

export default {
  name: "HomeHeader",
  components: {
    ChevronDoubleRightIcon
  }
}
</script>

<style scoped>

.bounceRight {
  animation: mainbtn 2s infinite;
  filter: none;
}

@keyframes mainbtn {
  0% {
    transform: translateX(-5px);
  }
  20% {
  transform: translateX(5px);
  }
  100% {
    transform: translateX(-5px);
  }
}

</style>
