<template>
  <StatusOnlineIcon v-if="icon === 'status-online'" />
  <ArrowLeftIcon v-else-if="icon === 'arrow-sm-left'" />
  <LightBulbIcon v-else-if="icon === 'light-bulb'" />
  <AnnotationIcon v-else-if="icon === 'annotation'" />
  <MailIcon v-else-if="icon === 'mail'" />
  <ChatIcon v-else-if="icon === 'chat'" />
  <GlobeIcon v-else-if="icon === 'globe'" />
  <ShieldCheckIcon v-else-if="icon === 'shield-check'" />
  <ChartBarIcon v-else-if="icon === 'chart-bar'" />
  <XIcon v-else />
</template>

<script>
import {
  AnnotationIcon,
  ArrowLeftIcon, ChartBarIcon,
  ChatIcon,
  GlobeIcon,
  LightBulbIcon,
  MailIcon, ShieldCheckIcon,
  StatusOnlineIcon, XIcon
} from "@heroicons/vue/solid";

export default {
  name: "FeatureIconResolver",
  components: {
    GlobeIcon,
    ArrowLeftIcon,
    LightBulbIcon,
    AnnotationIcon,
    MailIcon,
    ChatIcon,
    StatusOnlineIcon,
    ShieldCheckIcon,
    ChartBarIcon,
    XIcon
  },
  props: {
    icon: {
      default: ""
    }
  }
}
</script>

<style scoped>

</style>
