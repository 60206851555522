<template>
  <div class="bg-gray-50">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div class="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h2 class="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Häufig gestellte Fragen</h2>
        <dl class="mt-6 space-y-6 divide-y divide-gray-200">
          <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt class="text-lg">
              <DisclosureButton class="text-left w-full flex justify-between items-start text-gray-400">
                <span class="font-medium text-gray-900">
                  {{ faq.question }}
                </span>
                <span class="ml-6 h-7 flex items-center">
                  <ChevronDownIcon :class="[open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform']" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base text-gray-500">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>


<script>

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/outline'

const faqs = [
  {
    question: "Warum benötige ich das FINDER Portal?",
    answer:
        "Sie benötigen das FINDER Portal, um Ihren FINDER zu verwenden. Darin enthalten sind die monatlichen Kosten für die integrierte SIM-Karte sowie der Zugang zum FINDER Portal. Ohne diese beiden Elemente ist keine Positionsaufzeichnung möglich.",
  },
  {
    question: "Wann kann ich stornieren?",
    answer:
        "Gemäß Ihrer gewählten Lizenz können Sie Ihr Portal entweder nach einem Monat oder einem Jahr kündigen.",
  },
  {
    question: "Wo werden die Daten des FINDER-Portals gespeichert?",
    answer:
        "Ihre Privatsphäre steht immer an erster Stelle. Daher werden Ihre Daten mit höchster Sensibilität und nach dem Bundesdatenschutzgesetz auf einem deutschen Server mit deutscher Verwaltung gespeichert. Benutzerprofile werden niemals aus Ihren Daten erstellt und auch nicht für Dritte erstellt. Ihre Daten gehören Ihnen und bleiben es auch.",
  },
  {
    question: "Welche SIM-Karte ist bereits in meinem FINDER integriert?",
    answer:
        "In Ihrem FINDER befindet sich eine spezielle M2M SIM-Karte, die sich immer in das beste verfügbare Netzwerk einwählt – weltweit in 134 Ländern! So müssen Sie sich keine Sorgen machen, genügend Netzwerk in dem Bereich zu haben, in dem Sie Ihren FINDER verwenden. Die unterstützten Anbieter Ihres Landes finden Sie hier. Die SIM-Karte findet immer das beste verfügbare Netzwerk.",
  },
  {
    question: "Kann ich mehrere FINDER gleichzeitig aktivieren?",
    answer:
        "Ja! Nutzen Sie die Optionen im Aktivierungsprozess",
  },
  {
    question: "Ist es möglich ein weiteres Gerät zusammen mit einem bestehenden Abo abzurechnen?",
    answer:
        "Nein, Sie können jedoch problemlos ein weiteres Gerät aktivieren.",
  },
  // More questions...
]

export default {
  name: "FAQSection",
  components: {
    Disclosure, DisclosureButton, DisclosurePanel, ChevronDownIcon
  },
  data() {
    return {
      faqs
    }
  }
}
</script>

<style scoped>

</style>
