<template>
  <GlobalNavigation />
  <HomeHeader />
  <FeatureSection />
  <PricingSection />
  <FAQSection />
  <HomeFooter />
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import HomeHeader from "@/components/HomeHeader";
import GlobalNavigation from "@/components/GlobalNavigation";
import FeatureSection from "@/components/FeatureSection";
import PricingSection from "@/components/PricingSection";
import FAQSection from "@/components/FAQSection";
import HomeFooter from "@/components/HomeFooter";

export default {
  name: 'App',
  components: {
    HomeFooter,
    FAQSection,
    PricingSection,
    FeatureSection,
    GlobalNavigation,
    HomeHeader,
    // eslint-disable-next-line vue/no-unused-components
    HelloWorld
  }
}
</script>

<style>
</style>
