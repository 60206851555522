<template>
  <footer class="bg-white">
    <div class="max-w-7xl mx-auto py-8 px-4 overflow-hidden sm:px-6 lg:px-8">
      <p class="mt-8 text-center text-base text-gray-400">&copy; 2022 PAJ UG (haftungsbeschränkt). All rights reserved.</p>
    </div>
  </footer>
</template>
<script>

  const navigation = {
    main: [
    ],
    social: [
    ],
  }

export default {
  name: "HomeFooter",
  data() {
    return {
      navigation
    }
  }
}
</script>

<style scoped>

</style>
