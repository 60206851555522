<template>
  <div class="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8">
    <h2 class="text-3xl md:text-5xl font-extrabold text-gray-900 sm:leading-none sm:tracking-tight">Eine Preisgestaltung für alle Fälle</h2>
    <p class="mt-6 max-w-2xl text-xl text-gray-500">Alle Pakete beinhalten den gleichen Funktionsumfang. Einfach oder?<br>Sparen Sie durch das Wählen einer längeren Laufzeit.</p>

    <!-- Tiers -->
    <div class="mt-24 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
      <div v-for="tier in pricing.tiers" :key="tier.title" class="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col">
        <div class="flex-1">
          <h3 class="text-xl font-semibold text-gray-900">{{ tier.title }}</h3>
          <p v-if="tier.mostPopular" class="absolute top-0 py-1.5 px-4 bg-orange-light rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2"><LightningBoltIcon class="w-4 h-4 inline -mt-1 mr-1"/>Am beliebtesten</p>
          <p v-if="tier.mostValuable" class="absolute top-0 py-1.5 px-4 bg-orange-light rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2"><SparklesIcon class="w-4 h-4 inline -mt-1 mr-1"/>Preissieger</p>
          <p class="mt-4 flex items-baseline text-gray-900">
            <span class="text-5xl font-extrabold tracking-tight">{{ tier.price }}€</span>
            <span class="ml-1 text-xl font-semibold">{{ tier.frequency }}</span>

          </p>
          <span>inklusive Mehrwertsteuer</span>
          <p class="mt-6 text-gray-500">{{ tier.description }}</p>

          <!-- Feature list -->
          <ul role="list" class="mt-6 space-y-6">
            <li v-for="feature in tier.features" :key="feature" class="flex">
              <CheckIcon class="flex-shrink-0 w-6 h-6 text-orange" aria-hidden="true" />
              <span class="ml-3 text-gray-500">{{ feature }}</span>
            </li>
          </ul>
        </div>

        <a href="#" :class="[tier.mostPopular ? 'bg-orange text-white' : 'bg-orange/70 text-white', 'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium inline-flex items-center hover:bg-orange-light']">FINDER aktivieren <ChevronDoubleRightIcon class="ml-3 -mr-2.5 h-5 w-5" aria-hidden="true" /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/outline'
import {ChevronDoubleRightIcon, LightningBoltIcon, SparklesIcon} from "@heroicons/vue/solid";

const pricing = {
  tiers: [
    {
      title: 'Monatlich',
      price: 6.99,
      frequency: '/Monat',
      description: 'Monats-Abo mit Vorauszahlung (6,99€)',
      features: [
        'Live-Tracking',
        'Aktivierung der Sim-Karte',
        'Zugriff auf FINDER-Portal',
        '100 Tage Speicherdauer',
        'Professioneller Kundendienst',
      ],
      cta: 'Monthly billing',
      mostPopular: false,
      mostValuable: false
    },
    {
      title: 'Jährlich',
      price: 4.99,
      frequency: '/Monat',
      description: 'Jahres-Abo mit Vorauszahlung (59,88€)',
      features: [
        'Live-Tracking',
        'Aktivierung der Sim-Karte',
        'Zugriff auf FINDER-Portal',
        '100 Tage Speicherdauer',
        'Professioneller Kundendienst',
      ],
      cta: 'Monthly billing',
      mostPopular: true,
      mostValuable: false
    },
    {
      title: 'Zweijährlich',
      price: 4.16,
      frequency: '/Monat',
      description: 'Zwei-Jahres-Abo mit Vorauszahlung (99,99€)',
      features: [
        'Live-Tracking',
        'Aktivierung der Sim-Karte',
        'Zugriff auf FINDER-Portal',
        '100 Tage Speicherdauer',
        'Professioneller Kundendienst',
      ],
      cta: 'Monthly billing',
      mostPopular: false,
      mostValuable: true
    },
  ],
}

export default {
  name: "PricingSection",
  components: {
    CheckIcon,
    LightningBoltIcon,
    SparklesIcon,
    ChevronDoubleRightIcon
  },
  data() {
    return {
      pricing
    }
  }
}
</script>




<style scoped>

</style>
