<template>
  <div v-if="feature !== null" class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
    <div class="relative">
      <h3 class="text-2xl font-extrabold text-gray-100 tracking-tight sm:text-3xl">{{feature.caption}}</h3>
      <p class="mt-3 text-lg text-gray-200">{{feature.description}}</p>

      <dl class="mt-10 space-y-10">
        <div v-for="bullet in feature.bullets" :key="bullet.caption" class="relative">
          <dt>
            <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-orange-dark text-black">
              <FeatureIconResolver :icon="bullet.icon" class="h-7 w-7 text-white" />
            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-100">{{ bullet.heading }} <span v-if="bullet.plus" class="inline-flex items-center ml-1.5 px-2.5 py-0.5 rounded-md text-sm font-medium bg-orange-dark text-gray-100">PAJ+</span></p>
          </dt>
          <dd class="mt-2 ml-16 text-base text-gray-200">
            {{ bullet.description }}
          </dd>
        </div>
      </dl>
    </div>

    <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
      <!--          <svg class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">-->
      <!--            <defs>-->
      <!--              <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">-->
      <!--                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />-->
      <!--              </pattern>-->
      <!--            </defs>-->
      <!--            <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />-->
      <!--          </svg>-->
      <img class="relative mx-auto" width="490" :src="feature.image" alt="" />
    </div>
  </div>
</template>

<script>
import FeatureIconResolver from "@/components/features/FeatureIconResolver";
export default {
  name: "FeatureElementLeft",
  components: {FeatureIconResolver},
  props: {
    feature: {
      default: null
    }
  }
}
</script>

<style scoped>

</style>
